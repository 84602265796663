import { Box, Skeleton, Stack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import HouseCard from "../../components/HouseCard";
import SwitchButton from "../../components/SwitchButton";

import { firestore } from "../../config/firebase";

import useFirebaseQuery from "../../hooks/useFirebaseQuery";

import { House, HouseType } from "../../types/House";

const Houses = () => {
	const [toggle, setToggle] = useState<HouseType>("hire");
	const [houses, setHouses] = useState<House[]>([]);
	const [loading, setLoading] = useState(true);

	const { getAllDocuments: getHouses, deleteDocument } =
		useFirebaseQuery<House>("houses", firestore);

	const getAllHouses = useCallback(async () => {
		const allHouses = await getHouses();
		if (allHouses) setHouses(allHouses);
		setLoading(false);
	}, []);

	const deleteHouse = (house_id: string) => {
		setHouses(houses.filter((h) => h.id !== house_id));
		deleteDocument(house_id);
	};

	useEffect(() => {
		getAllHouses();
	}, [getAllHouses]);

	return (
		<Box
			width="100%"
			height="100%"
			backgroundColor="defaultTheme.foregroundHard"
			borderRadius="20px"
			padding="10px 20px"
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				marginBottom="15px"
			>
				<SwitchButton
					handleSwitchToggleToRent={() => setToggle("hire")}
					handleSwitchToggleToSale={() => setToggle("sell")}
					toggleValue={toggle}
				/>
			</Box>

			<Box>
				{houses
					.filter((house) =>
						toggle === "hire" ? house.hiring : house.selling
					)
					.map((house) => (
						<HouseCard
							houseType={toggle}
							title={house.title}
							subtitle={house.subtitle}
							price={
								toggle === "hire"
									? house.hire_price
									: house.sell_price
							}
							houseImage={house.images[0].link}
							house_id={house.id}
							key={house.id}
							deleteHouse={deleteHouse}
						/>
					))}
				{loading && (
					<Stack>
						<Skeleton height="90px" />
						<Skeleton height="90px" />
						<Skeleton height="90px" />
					</Stack>
				)}
			</Box>
		</Box>
	);
};

export default Houses;
