import { Box } from "@chakra-ui/react";

const Header = () => {
	return (
		<Box
			width={"100%"}
			backgroundColor="defaultTheme.foreground"
			padding="10px 0 10px 0"
			display={"flex"}
			alignItems={"center"}
			justifyContent={"center"}
			boxShadow="md"
			position="absolute"
		>
			<Box
				width={"70%"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"space-between"}
			>
				<img src="/assets/logo.svg" alt="logo" width={80} />
			</Box>
		</Box>
	);
};

export default Header;
