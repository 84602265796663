import { Navigate, Route, Routes } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import Home from "./pages/home";
import Login from "./pages/Login";
import DashBoard from "./pages/home/Dashboard";
import Houses from "./pages/home/Houses";
import CreateOrEditHouse from "./pages/home/CreateOrEditHouse";
import { auth } from "./config/firebase";

export const AppRoutes = () => {
	const [user, loading] = useAuthState(auth);

	if (loading) return null;
	return (
		<Routes>
			{user ? (
				<Route path="/" element={<Home />}>
					{/* <Route index element={<DashBoard />} /> */}
					{/* <Route path="/houses" element={<Houses />} /> */}
					<Route index element={<Houses />} />
					<Route
						path="/details/:id"
						element={<CreateOrEditHouse />}
					/>
					<Route path="/create" element={<CreateOrEditHouse />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Route>
			) : (
				<>
					<Route path="/" element={<Login />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</>
			)}
		</Routes>
	);
};
