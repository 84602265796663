import {
	Box,
	Button,
	Flex,
	Spinner,
	Stack,
	Text,
	useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import CheckboxInput from "../../components/CheckboxInput";
import ControlledInput from "../../components/ControlledInput";

import {
	formatHouseFromFormToFirebase,
	formatHouseGottenToFormModel,
	HouseFormatted,
} from "../../helpers/house-form.helper";
import {
	ATTRIBUTE_FIELDS,
	COMMODITIES_FIELD,
	COMMON_FIELDS,
	HOUSE_TYPE_FIELD,
} from "../../config/forms";
import { firestore } from "../../config/firebase";

import useFirebaseQuery from "../../hooks/useFirebaseQuery";

import { House, HouseImage } from "../../types/House";
import ImageInput from "../../components/ImageInput";
import useFirebaseImageDropzone from "../../hooks/useFirebaseImageDropzone";

const IMAGE_INPUT_NAME = "images";

const CreateOrEditHouse = () => {
	let { id } = useParams();
	const navigate = useNavigate();
	const toast = useToast();

	const {
		getDocumentById: getHouseById,
		editDocument,
		createDocument,
	} = useFirebaseQuery<House>("houses", firestore);

	const [loading, setLoading] = useState(true);
	const [house, setHouse] = useState<House>();

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		register,
		control,
		watch,
		reset,
		setValue,
	} = useForm({ mode: "all" });

	const house_type = watch("house_type");
	const images = watch(IMAGE_INPUT_NAME);

	const { getInputProps, getRootProps } = useFirebaseImageDropzone({
		setValue,
		images: images || [],
		name: IMAGE_INPUT_NAME,
		register,
	});

	const getHouseAndUpdate = useCallback(async () => {
		if (id) {
			const houseGotten = await getHouseById(id);
			if (!houseGotten) return navigate("/", { replace: true });
			setHouse(houseGotten);
			const houseFormatted = formatHouseGottenToFormModel(houseGotten);
			reset(houseFormatted);
		} else {
			reset(undefined);
		}
		setLoading(false);
	}, [id]);

	const openToast = (
		title: string,
		description: string,
		status: "success" | "error"
	) => {
		toast({
			title,
			description,
			status,
			duration: 9000,
			isClosable: true,
		});
	};

	const openToastOnSuccess = (type: "create" | "edit") =>
		openToast(
			"SUCESSO",
			type === "create"
				? "Imóvel criado com sucesso!"
				: "Imóvel editado com sucesso",
			"success"
		);
	const openToastOnError = (type: "create" | "edit") =>
		openToast(
			"ERRO",
			type === "create"
				? "Não foi possível criar o imóvel! Tente novamente mais tarde!"
				: "Não foi possível editar o imóvel! Tente novamente mais tarde!",
			"error"
		);

	const editOrCreateHouse = async (formValues: HouseFormatted) => {
		const houseFormatted = formatHouseFromFormToFirebase(formValues);
		const houseEditted = { ...house, ...houseFormatted };

		if (id)
			await editDocument(
				id,
				houseEditted as House,
				() => {
					openToastOnSuccess("edit");
					reset(formValues);
				},
				() => openToastOnError("edit")
			);
		else {
			await createDocument(
				houseEditted as House,
				() => {
					openToastOnSuccess("create");
					reset(formValues);
				},
				() => openToastOnError("create")
			);
		}
	};

	useEffect(() => {
		getHouseAndUpdate();
	}, [getHouseAndUpdate]);

	return (
		<>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" height="100%">
					<Spinner size="xl" />
				</Flex>
			) : (
				<Box
					w="100%"
					h="100%"
					bgColor="defaultTheme.foregroundHard"
					borderRadius="10px"
					p="30px"
					display="flex"
					position="relative"
				>
					<Box
						flex={1}
						height="100%"
						borderRight="1px solid rgba(0, 0, 0, 0.17)"
					>
						<Text fontSize="20px" fontWeight="bold">
							Editar Imóvel
						</Text>
						<Box
							w="60%"
							h="100%"
							id="left-form"
							paddingTop="10px"
							paddingRight="30px"
						>
							<Text marginBottom="5px">Esse imóvel é para:</Text>
							<CheckboxInput
								control={control}
								dir="row"
								fields={HOUSE_TYPE_FIELD}
								errorMessage={errors?.house_type}
								name="house_type"
							/>
							{house_type &&
							(house_type as string[]).includes("hiring") ? (
								<ControlledInput
									registerProps={register("hire_price", {
										required: true,
									})}
									isInvalid={errors.hire_price}
									label=""
									name="hire_price"
									placeholder="Preço do Aluguel"
									inputType="number"
								/>
							) : null}
							{house_type &&
							(house_type as string[]).includes("selling") ? (
								<ControlledInput
									registerProps={register("sell_price", {
										required: true,
									})}
									isInvalid={errors.sell_price}
									label=""
									name="sell_price"
									placeholder="Preço da Venda"
									inputType="number"
								/>
							) : null}
							<Stack
								direction="column"
								spacing="10px"
								marginBottom="20px"
							>
								{COMMON_FIELDS.map(
									({ name, placeholder, required }) => (
										<ControlledInput
											registerProps={register(name, {
												required:
													required &&
													"O campo é obrigatório",
											})}
											isInvalid={errors[name]}
											label=""
											name={name}
											placeholder={placeholder}
											key={name}
										/>
									)
								)}
							</Stack>
							<Text fontWeight="medium" fontSize="18px">
								Quantidade de
							</Text>
							<Stack direction="column" spacing="0px">
								{ATTRIBUTE_FIELDS.map(
									({
										name,
										placeholder,
										LeftIcon,
										inputType,
									}) => (
										<ControlledInput
											registerProps={register(name, {
												required:
													"O campo é obrigatório",
											})}
											isInvalid={errors[name]}
											label=""
											LeftIcon={LeftIcon}
											name={name}
											placeholder={placeholder}
											inputType={inputType}
											key={name}
										/>
									)
								)}
							</Stack>
						</Box>
					</Box>
					<Box flex={1} height="100%" paddingX="30px">
						<Text
							fontWeight="medium"
							fontSize="18px"
							marginBottom="10px"
						>
							Selecione mais comodidades que esse imóvel
							disponibiliza:
						</Text>
						<CheckboxInput
							control={control}
							dir="column"
							fields={COMMODITIES_FIELD}
							errorMessage={errors.commodities}
							name="commodities"
						/>
						<ImageInput
							isInvalid={!!errors[IMAGE_INPUT_NAME]}
							images={images || []}
							onDeleteImage={(imageName) =>
								setValue(
									IMAGE_INPUT_NAME,
									images.filter(
										(img: HouseImage) =>
											img.name !== imageName
									)
								)
							}
							getInputProps={getInputProps}
							getRootProps={getRootProps}
							errorMessage={errors[IMAGE_INPUT_NAME]?.message}
							control={control}
							name={IMAGE_INPUT_NAME}
						/>
						<Flex
							justifyContent="flex-end"
							marginTop={
								images && images.length > 0 ? "30px" : "100px"
							}
						>
							<Button
								isLoading={isSubmitting}
								onClick={handleSubmit(editOrCreateHouse)}
								colorScheme="green"
								w="200px"
								marginRight="-40px"
							>
								{id ? "SALVAR" : "PUBLICAR"}
							</Button>
						</Flex>
					</Box>
				</Box>
			)}
		</>
	);
};

export default CreateOrEditHouse;
