import { House, HouseImage } from "../types/House";

const getHouseTypes = ({ hiring, selling }: House) => {
	let house_types: string[] = [];

	if (hiring) house_types = [...house_types, "hiring"];
	if (selling) house_types = [...house_types, "selling"];

	return house_types;
};

const getCommodities = ({
	available_kitchen,
	available_parking,
	available_private_pool,
	available_tv,
	available_wifi,
}: House) => {
	let commodities: string[] = [];

	if (available_kitchen) commodities = [...commodities, "available_kitchen"];
	if (available_parking) commodities = [...commodities, "available_parking"];
	if (available_private_pool)
		commodities = [...commodities, "available_private_pool"];
	if (available_tv) commodities = [...commodities, "available_tv"];
	if (available_wifi) commodities = [...commodities, "available_wifi"];

	return commodities;
};

export type HouseFormatted = {
	[key: string]: string | boolean | string[] | number | HouseImage[];
};

const COMMODITIES = [
	"available_kitchen",
	"available_parking",
	"available_private_pool",
	"available_tv",
	"available_wifi",
];

export const formatHouseGottenToFormModel = (house: House): HouseFormatted => ({
	...house,
	house_type: getHouseTypes(house),
	commodities: getCommodities(house),
});

export const formatHouseFromFormToFirebase = ({
	commodities,
	house_type,
	...house
}: HouseFormatted): Partial<House> => {
	const commoditiesUpdatted = COMMODITIES.map((v) => ({
		[v]: (commodities as string[]).includes(v),
	}));

	return {
		...house,
		hiring: (house_type as string[]).includes("hiring"),
		selling: (house_type as string[]).includes("selling"),
		...Object.assign({}, ...commoditiesUpdatted),
	};
};
