import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { UseFormRegister } from "react-hook-form";

import { storage } from "../config/firebase";

type HookProps = {
	maxFiles?: number;
	register: UseFormRegister<any>;
	name: string;
	images: FormattedImage[];
	setValue: (name: string, value: any) => void;
};

type FormattedImage = {
	link: string;
	name: string;
};

const useFirebaseImageDropzone = ({
	maxFiles = 10,
	name,
	images,
	setValue,
}: HookProps) => {
	const uploadOnFirebase = async (file: File): Promise<FormattedImage> => {
		const storageRef = ref(storage, `house_images/${file.name}`);
		const uploadedFile = await uploadBytes(storageRef, file);
		const downloadUrl = await getDownloadURL(uploadedFile.ref);

		return {
			name: file.name,
			link: downloadUrl,
		};
	};

	const onDropAccepted = useCallback(
		async (files: File[]) => {
			const acceptedImages = [];
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const imageUploaded = await uploadOnFirebase(file);
				acceptedImages.push(imageUploaded);
			}

			setValue(name, [...images, ...acceptedImages]);
		},
		[images, name, setValue]
	);

	const validateIfFileHasAlreadyBeenDropped = (file: File) => {
		if (images.length >= maxFiles)
			return {
				code: "file-number-limit",
				message: "Número de arquivos máximo atingido",
			};
		else if (
			images.filter((image) => image.name === file.name).length > 0
		) {
			return {
				code: "file-already-exists",
				message: "O arquivo já foi incluído",
			};
		}

		return null;
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDropAccepted,
		accept: {
			"image/jpeg": [".jpg", ".jpeg"],
			"image/png": [".png"],
		},
		maxFiles: maxFiles,
		validator: validateIfFileHasAlreadyBeenDropped,
	});

	return {
		getRootProps,
		getInputProps,
	};
};

export default useFirebaseImageDropzone;
