import {
	FormControl,
	CheckboxGroup,
	Checkbox,
	FormErrorMessage,
	Stack,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";

export type CheckboxField = {
	value: string;
	Label: () => JSX.Element;
};

type CheckboxInputProps = {
	control: any;
	fields: CheckboxField[];
	dir: "row" | "column";
	errorMessage?: string;
	name: string;
};

const CheckboxInput = ({
	control,
	fields,
	dir,
	errorMessage,
	name,
}: CheckboxInputProps) => {
	return (
		<FormControl isInvalid={!!errorMessage}>
			<Controller
				name={name}
				control={control}
				render={({ field: { ref, ...rest } }) => (
					<CheckboxGroup {...rest}>
						<Stack
							direction={dir}
							spacing={dir === "row" ? "20px" : "5px"}
						>
							{fields.map(({ Label, value }, i) => (
								<Checkbox
									value={value}
									key={`${i}-value`}
									borderColor="black"
								>
									<Label />
								</Checkbox>
							))}
						</Stack>
					</CheckboxGroup>
				)}
				rules={{
					required: "Selecione ao menos um",
				}}
			/>
			<FormErrorMessage>Selecione ao menos uma opção</FormErrorMessage>
		</FormControl>
	);
};
export default CheckboxInput;
