import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export type RouteType = {
	path: string;
	Icon: (props: any) => JSX.Element;
};

type SideBarProps = {
	routeSelected: string;
	routes: RouteType[];
};

const SideBar = ({ routes, routeSelected }: SideBarProps) => {
	return (
		<Box
			backgroundColor="defaultTheme.foregroundSoft"
			borderRadius="10px 0  0 10px"
			display="flex"
			flexDirection="column"
			height="min-content"
			color="defaultTheme.primary"
			overflow="hidden"
		>
			{routes.map(({ Icon, path }) => (
				<Link to={path} key={path}>
					<Box
						width="60px"
						height="50px"
						display="flex"
						alignItems="center"
						justifyContent="center"
						backgroundColor={
							path === routeSelected ? "defaultTheme.light" : ""
						}
					>
						<Icon size={25} />
					</Box>
				</Link>
			))}
		</Box>
	);
};

export default SideBar;
