import { Box, Text } from "@chakra-ui/react";
import { HouseType } from "../types/House";

type SwitchButtonProps = {
	toggleValue: HouseType;
	handleSwitchToggleToRent: () => void;
	handleSwitchToggleToSale: () => void;
};

const SwitchButton = ({
	handleSwitchToggleToRent,
	handleSwitchToggleToSale,
	toggleValue,
}: SwitchButtonProps) => {
	return (
		<Box display="flex" margin="10px">
			<Box
				borderRadius="50px 0 0 50px"
				boxShadow="lg"
				cursor="pointer"
				display="flex"
				alignItems="center"
				justifyContent="center"
				width="220px"
				padding="7px"
				backgroundColor={
					toggleValue === "hire"
						? "defaultTheme.primary"
						: "defaultTheme.light"
				}
				onClick={() => handleSwitchToggleToRent()}
			>
				<Text
					fontSize="14px"
					fontWeight={toggleValue === "hire" ? "700" : "500"}
					textAlign="center"
					color={
						toggleValue === "hire"
							? "defaultTheme.light"
							: "defaultTheme.primary"
					}
				>
					ALUGUEIS DA TEMPORADA
				</Text>
			</Box>

			<Box
				borderRadius="0 50px 50px 0"
				boxShadow="lg"
				cursor="pointer"
				display="flex"
				alignItems="center"
				justifyContent="center"
				width="220px"
				padding="7px"
				backgroundColor={
					toggleValue === "sell"
						? "defaultTheme.primary"
						: "defaultTheme.light"
				}
				onClick={() => handleSwitchToggleToSale()}
			>
				<Text
					fontSize="14px"
					fontWeight={toggleValue === "sell" ? "700" : "500"}
					textAlign="center"
					color={
						toggleValue === "sell"
							? "defaultTheme.light"
							: "defaultTheme.primary"
					}
				>
					IMÓVEIS À VENDA
				</Text>
			</Box>
		</Box>
	);
};

export default SwitchButton;
