import {
	Box,
	FormControl,
	FormLabel,
	HStack,
	Icon,
	Text,
	TextProps,
} from "@chakra-ui/react";
import { DropzoneRootProps } from "react-dropzone";
import { Control, Controller } from "react-hook-form";
import { MdClose } from "react-icons/md";

type ImageSrc = {
	link: string;
	name: string;
};

type ImageInputProps = {
	isInvalid: boolean;
	images?: ImageSrc[];
	onDeleteImage: (imageName: string) => void;
	getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
	getInputProps: (props?: any) => any;
	errorMessage: string;
	name: string;
	control: Control;
};

const IMAGE_INPUT_TEXT: TextProps = {
	fontSize: "14px",
	fontWeight: "medium",
	textAlign: "center",
};

const ImageInput = ({
	isInvalid,
	images,
	onDeleteImage,
	getRootProps,
	getInputProps,
	errorMessage,
	control,
	name,
}: ImageInputProps) => {
	return (
		<FormControl isInvalid={isInvalid} paddingTop="40px">
			<FormLabel marginBottom="20px">Imagens do Imóvel:</FormLabel>
			<Box
				{...getRootProps()}
				width="100%"
				height="100px"
				borderRadius="10px"
				border="2px dashed black"
				marginBottom="25px"
				display="flex"
				justifyContent="center"
				alignItems="center"
				backgroundColor="#F2EEDE"
			>
				<Controller
					render={({
						field: { onChange },
						fieldState: { error },
					}) => (
						<>
							<input {...getInputProps({ onChange })} />
							{error?.message ? (
								<Text
									{...IMAGE_INPUT_TEXT}
									color="red.500"
									fontWeight="bold"
								>
									{error.message}
								</Text>
							) : (
								<Text {...IMAGE_INPUT_TEXT}>
									Arraste e solte imagens aqui, ou clique para
									adicionar imagens
								</Text>
							)}
						</>
					)}
					name={name}
					control={control}
					rules={{
						required: "Insira ao menos uma imagem",
					}}
				/>
			</Box>
			{images && images.length > 0 && (
				<HStack
					w="100%"
					height="160px"
					overflowX="auto"
					overflowY="hidden"
				>
					{images.map((house_image, i) => (
						<Box
							flex="1"
							maxWidth="35%"
							minWidth="35%"
							height="120px"
							borderRadius="10px"
							key={i}
							backgroundImage={`url(${house_image.link})`}
							backgroundSize="cover"
							backgroundPosition="center"
							backgroundRepeat="no-repeat"
							position="relative"
						>
							<Box
								width="30px"
								height="30px"
								backgroundColor="red.500"
								position="absolute"
								right="5px"
								top="5px"
								borderRadius="50%"
								display="flex"
								justifyContent="center"
								alignItems="center"
								onClick={() => onDeleteImage(house_image.name)}
								cursor="pointer"
							>
								<Icon as={MdClose} size={60} color="white" />
							</Box>
						</Box>
					))}
				</HStack>
			)}
		</FormControl>
	);
};

export default ImageInput;
