import {
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	InputProps,
	Stack,
} from "@chakra-ui/react";

export type ControlledInputProps = {
	isInvalid: boolean;
	label: string;
	name: string;
	placeholder: string;
	errorMessage?: string;
	registerProps: InputProps;
	inputType?: React.HTMLInputTypeAttribute;
	LeftIcon?: (props: any) => JSX.Element;
};

const ControlledInput = ({
	isInvalid,
	label,
	name,
	placeholder,
	errorMessage,
	registerProps,
	inputType,
	LeftIcon,
}: ControlledInputProps) => {
	return (
		<FormControl isInvalid={isInvalid}>
			<FormLabel htmlFor={name}>{label}</FormLabel>
			<Stack direction="row" spacing="10px" alignItems="center">
				{LeftIcon && <LeftIcon />}
				<Input
					id={name}
					variant="flushed"
					placeholder={placeholder}
					borderColor="#00000080"
					type={inputType}
					{...registerProps}
				/>
			</Stack>
			<FormErrorMessage>{isInvalid && errorMessage}</FormErrorMessage>
		</FormControl>
	);
};
export default ControlledInput;
