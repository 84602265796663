import { Box } from "@chakra-ui/react";
import { AiFillHome } from "react-icons/ai";
import { MdOutlineGrading } from "react-icons/md";
import { FaUserCircle, FaPlus } from "react-icons/fa";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import SideBar, { RouteType } from "../../components/SideBar";
import { Outlet, useLocation } from "react-router-dom";

const MENU_ROUTES: RouteType[] = [
	// {
	// 	path: "/",
	// 	Icon: AiFillHome,
	// },
	{
		path: "/",
		Icon: AiFillHome,
	},
	{
		path: "/create",
		Icon: FaPlus,
	},
];

const Home = () => {
	const location = useLocation();

	return (
		<Box>
			<Header />
			<Box
				width="100%"
				padding="80px 0 0px 0"
				backgroundColor="defaultTheme.foreground"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Box
					display="flex"
					justifyContent="center"
					width="100%"
					padding="40px"
				>
					<SideBar
						routes={MENU_ROUTES}
						routeSelected={location.pathname}
					/>
					<Box
						backgroundColor="defaultTheme.light"
						width="100%"
						maxWidth="1200px"
						minHeight="600px"
						borderRadius="0 20px 20px 20px"
						padding="20px"
					>
						<Outlet />
					</Box>
				</Box>
			</Box>
			<Footer />
		</Box>
	);
};

export default Home;
