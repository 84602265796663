import { CheckboxField } from "../components/CheckboxInput";

import { MdOutlineBed, MdBathtub, MdFamilyRestroom } from "react-icons/md";
import { Box, Icon, Stack, Text } from "@chakra-ui/react";

export const HOUSE_TYPE_FIELD: CheckboxField[] = [
	{
		value: "hiring",
		Label: () => <Text>Alugar </Text>,
	},
	{
		value: "selling",
		Label: () => <Text>Vender </Text>,
	},
];

type SimpleField = {
	name: string;
	placeholder: string;
	LeftIcon?: (props: any) => JSX.Element;
	inputType?: React.HTMLInputTypeAttribute;
	required: boolean;
};

export const COMMON_FIELDS: SimpleField[] = [
	{
		name: "title",
		placeholder: "Título para o imóvel",
		required: true,
	},
	{
		name: "link",
		placeholder: "Link do anúncio",
		required: true,
	},
	{
		name: "subtitle",
		placeholder: "Sub-título para o imóvel",
		required: true,
	},
	{
		name: "address",
		placeholder: "Endereço",
		required: false,
	},
	{
		name: "description",
		placeholder: "Descrição",
		required: true,
	},
];

export const ATTRIBUTE_FIELDS: Omit<SimpleField, "required">[] = [
	{
		name: "bedroom_number",
		placeholder: "Quartos:",
		LeftIcon: () => (
			<Icon as={MdOutlineBed} color="#414141" boxSize="1.25em" />
		),
		inputType: "number",
	},
	{
		name: "bathroom_number",
		placeholder: "Banheiros:",
		LeftIcon: () => (
			<Icon as={MdBathtub} color="#414141" boxSize="1.25em" />
		),
		inputType: "number",
	},
	{
		name: "allowed_guests_number",
		placeholder: "Hóspedes:",
		LeftIcon: () => (
			<Icon as={MdFamilyRestroom} color="#414141" boxSize="1.25em" />
		),
		inputType: "number",
	},
];

export const COMMODITIES_FIELD: CheckboxField[] = [
	{
		value: "available_kitchen",
		Label: () => <Text>Cozinha </Text>,
	},
	{
		value: "available_tv",
		Label: () => <Text>TV </Text>,
	},
	{
		value: "available_private_pool",
		Label: () => <Text>Piscina Privativa</Text>,
	},
	{
		value: "available_wifi",
		Label: () => <Text>Wi - Fi </Text>,
	},
	{
		value: "available_parking",
		Label: () => <Text>Estacionamento incluído </Text>,
	},
];
