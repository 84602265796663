import { Box, Text, Image } from "@chakra-ui/react";
import { ImWhatsapp } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import { useBreakpointValue } from "@chakra-ui/react";

const HousesSection = () => {
	const currentTime = new Date();
	const year = currentTime.getFullYear();

	const variant = useBreakpointValue({ base: 200, md: 90 });

	return (
		<Box
			padding="30px 0"
			backgroundColor="defaultTheme.secondary"
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			color="defaultTheme.light"
			position="relative"
			bottom={0}
			overflow="hidden"
		>
			<Box
				width="80%"
				display="flex"
				justifyContent={["center", "center", "flex-start"]}
			>
				<Image
					src="/assets/logo2.svg"
					alt="logo"
					width={variant}
					height={variant}
				/>
			</Box>
			<Box
				display="flex"
				flexDirection={["column", "column", "row"]}
				alignItems="space-between"
				borderBottom="solid white 1px"
				width="80%"
				padding="10px 0 20px 0"
			>
				<Box
					display="flex"
					justifyContent={["center", "center", "flex-start"]}
					fontSize={["14px", "16px", "14px"]}
				>
					<Text
						maxWidth="400px"
						marginBottom={["20px", "20px", "0"]}
						textAlign={["center", "center", "initial"]}
					>
						Empresa especializada em locação e venda de casas
						localizadas na região de São Paulo, tendo como sua
						missão trazer aos seus clientes uma hospedagem tranquila
						e prazerosa durante sua estadia.
					</Text>
				</Box>

				<Box
					display="flex"
					flex="1"
					alignItems={["center", "center", "flex-end"]}
					flexDirection="column"
				>
					<Box
						display="flex"
						alignItems="center"
						flexDirection={["row-reverse", "row-reverse", "row"]}
					>
						<Text margin="0px 10px 0 10px">+55 11 94728-8745</Text>
						<ImWhatsapp />
					</Box>

					<Box
						display="flex"
						alignItems="center"
						flexDirection={["row-reverse", "row-reverse", "row"]}
					>
						<Text margin="0px 10px 0 10px">@temporadafavorita</Text>
						<FaInstagram />
					</Box>
				</Box>
			</Box>
			<Box
				marginTop="10px"
				maxWidth="70%"
				textAlign={["center", "center", "initial"]}
				marginBottom={["70px", "70px", "0"]}
				fontSize={["14px", "16px", "18px"]}
			>
				<Text>
					© {year} Temporada Favorita. Todos os direitos reservados
				</Text>
			</Box>

			<Box position="absolute" right="-110" bottom="-110px" zIndex="0">
				<Image
					src="/assets/circle.svg"
					alt="logo"
					width={200}
					height={200}
				/>
			</Box>

			<Box position="absolute" right="0" top="-90px" zIndex="0">
				<Image
					src="/assets/dotsright.svg"
					alt="logo"
					width={200}
					height={200}
				/>
			</Box>

			<Box position="absolute" left="0" bottom="-90px" zIndex="0">
				<Image
					src="/assets/dotsleft.svg"
					alt="logo"
					width={220}
					height={220}
				/>
			</Box>
		</Box>
	);
};

export default HousesSection;
