export const colors = {
	defaultTheme: {
		primary: "#9A573B",
		primaryDisable: "#C6A38D",
		secondary: "#D8843E",
		background: "#0D5651",
		backgroundHover: "#1F938B",
		foreground: "#F2EEDE",
		foregroundSoft: "#F8F6EA",
		foregroundHard: "#E7DFC2",
		light: "#FFFEF7",
	},
};
