import { Box, Button, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { HouseType } from "../types/House";

type HouseCardProps = {
	title: string;
	subtitle: string;
	price: number;
	houseType: HouseType;
	houseImage: string;
	house_id: string;
	deleteHouse: (houseId: string) => void;
};

const HouseCard = ({
	title,
	subtitle,
	price,
	houseType,
	houseImage,
	house_id,
	deleteHouse,
}: HouseCardProps) => {
	return (
		<Box
			width="100%"
			backgroundColor="defaultTheme.light"
			padding="20px"
			marginBottom="10px"
			borderRadius="10px"
			display="flex"
			alignItems="center"
			justifyContent="space-between"
		>
			<Box display="flex" alignItems="center" flex={2}>
				<Box
					backgroundImage={`url(${houseImage})`}
					backgroundPosition="center"
					backgroundSize="cover"
					borderRadius="15px"
					min-width="50px"
					min-height="50px"
					width="50px"
					height="50px"
					marginRight="20px"
				></Box>

				<Box>
					<Text fontSize="16px" fontWeight="600">
						{title}
					</Text>
					<Text fontSize="14px">{subtitle}</Text>
				</Box>
			</Box>

			<Box display="flex" alignItems="baseline" minWidth="130px" flex={1}>
				<Text
					fontSize="22px"
					marginRight="5px"
					fontWeight="600"
					display="inline"
				>
					R$ {price}
				</Text>
				{houseType === "hire" && (
					<Text fontSize="14px" display="inline">
						por noite
					</Text>
				)}
			</Box>

			<Box
				display="flex"
				alignItems="center"
				flex={2}
				justifyContent="flex-end"
			>
				<Button
					minWidth="120px"
					fontWeight="600"
					backgroundColor="defaultTheme.light"
					color="defaultTheme.background"
					border="solid 2px"
					_hover={{ backgroundColor: "defaultTheme.foregroundSoft" }}
					marginRight="10px"
					onClick={() => deleteHouse(house_id)}
				>
					Remover anúncio
				</Button>
				<Link to={`/details/${house_id}`}>
					<Button
						minWidth="120px"
						fontWeight="600"
						backgroundColor="defaultTheme.background"
						color="defaultTheme.light"
						_hover={{
							backgroundColor: "defaultTheme.backgroundHover",
						}}
					>
						Editar imóvel
					</Button>
				</Link>
			</Box>
		</Box>
	);
};

export default HouseCard;
