import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { colors } from "./config/colors";
import { AppRoutes } from "./routes";

const theme = extendTheme({ colors });

function App() {
	return (
		<ChakraProvider theme={theme}>
			<AppRoutes />
		</ChakraProvider>
	);
}

export default App;
