import { Box, Button, Image, Stack, Text, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { signInWithEmailAndPassword } from "firebase/auth";

import ControlledInput from "../components/ControlledInput";
import { auth } from "../config/firebase";

type User = {
	email: string;
	password: string;
};

const Login = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isValid, isSubmitting },
	} = useForm({ mode: "onChange" });

	const toast = useToast();

	const login = async (formValues: any) => {
		try {
			const { email, password } = formValues as User;
			await signInWithEmailAndPassword(auth, email, password);
		} catch (err) {
			toast({
				title: "ERRO",
				description:
					"Confira as informações passadas e tente novamente!",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	};

	return (
		<Box
			backgroundColor="defaultTheme.background"
			w="100vw"
			h="100vh"
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<Box
				backgroundColor="defaultTheme.light"
				minHeight="100px"
				borderRadius="15px"
				boxShadow="md"
				display="flex"
				paddingX="5%"
				paddingY="30px"
				alignItems="center"
				flexDirection="column"
			>
				<Image
					src="/assets/logo.svg"
					alt="Temporada Favorita"
					boxSize="60px"
					objectFit="contain"
				/>
				<Text
					fontWeight="bold"
					fontSize="24px"
					p="10px"
					color="defaultTheme.background"
				>
					Bem Vindo
				</Text>
				<Text
					fontWeight="medium"
					fontSize="16px"
					textAlign="center"
					p="10px"
					marginBottom="20px"
				>
					Para acessar o setor administrativo faça login abaixo
				</Text>
				<Stack
					direction="column"
					spacing="15px"
					w="100%"
					alignItems="center"
					marginBottom="30px"
				>
					<ControlledInput
						registerProps={register("email", {
							required: true,
							validate: (value: string) =>
								(value.includes("@") &&
									value.includes(".com")) ||
								"Coloque um e-mail válido",
						})}
						isInvalid={errors.email}
						errorMessage={
							errors.email ? errors.email.message : undefined
						}
						label=""
						name="email"
						placeholder="E-mail"
						inputType="email"
					/>
					<ControlledInput
						registerProps={register("password", {
							required: true,
						})}
						isInvalid={errors.password}
						errorMessage="Campo obrigatório"
						label=""
						name="password"
						placeholder="Senha"
						inputType="password"
					/>
				</Stack>
				<Button
					width="50%"
					colorScheme="green"
					onClick={handleSubmit(login)}
					isDisabled={!isValid}
					isLoading={isSubmitting}
				>
					LOGIN
				</Button>
			</Box>
		</Box>
	);
};

export default Login;
