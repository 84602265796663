import {
	getDocs,
	collection,
	Firestore,
	doc,
	getDoc,
	addDoc,
	setDoc,
	deleteDoc,
} from "firebase/firestore";

const useFirebaseQuery = <T>(collection_name: string, db: Firestore) => {
	const getAllDocuments = async (): Promise<T[] | undefined> => {
		try {
			const doscsQuery = await getDocs(collection(db, collection_name));

			const docs = doscsQuery.docs.map((item) => ({
				...item.data(),
				id: item.id,
			}));

			return docs as unknown as T[];
		} catch (err) {
			console.log(
				`ERRO AO BUSCAR DOCUMENTOS DE ${collection_name.toUpperCase()}: ${err}`
			);
		}
	};

	const getDocumentById = async (uid: string): Promise<T | undefined> => {
		try {
			const existingDocRef = doc(db, collection_name, uid);
			const existingDoc = await getDoc(existingDocRef);

			if (!existingDoc.exists()) return;

			return { ...existingDoc.data() } as unknown as T;
		} catch (err) {
			console.log(
				`ERRO AO BUSCAR DOCUMENTO DE ${collection_name.toUpperCase()}: ${err}`
			);
		}
	};

	const createDocument = async (
		data: T,
		callback?: (uid?: string) => Promise<void> | void,
		onError?: () => void
	) => {
		try {
			const newDocument = await addDoc(
				collection(db, collection_name),
				data
			);
			if (callback) await callback(newDocument.id);
		} catch (err) {
			console.log(
				`ERRO AO CRIAR DOCUMENTO EM ${collection_name.toUpperCase()}: ${err}`
			);
			if (onError) onError();
		}
	};

	const editDocument = async (
		uid: string,
		data: T,
		callback?: (uid?: string) => Promise<void> | void,
		onError?: () => void
	) => {
		try {
			await setDoc(doc(db, collection_name, uid), data);
			if (callback) await callback(uid);
		} catch (err) {
			console.log(
				`ERRO AO EDITAR DOCUMENTO EM ${collection_name.toUpperCase()}: ${err}`
			);
			if (onError) onError();
		}
	};

	const deleteDocument = async (uid: string) => {
		try {
			await deleteDoc(doc(db, collection_name, uid));
		} catch (err) {
			console.log(
				`ERRO AO DELETAR DOCUMENTO EM ${collection_name.toUpperCase()}: ${err}`
			);
		}
	};

	return {
		getAllDocuments,
		getDocumentById,
		createDocument,
		editDocument,
		deleteDocument,
	};
};

export default useFirebaseQuery;
